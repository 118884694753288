/* src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

/* containers */
html,
body {
	overflow: auto;
	overscroll-behavior: none;
	background-color: white;
	display: flex;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
}

.App {
	font-family: sans-serif;
	text-align: center;
}

.body {
	display: grid;
	box-sizing: border-box;
	row-gap: 15px;
	column-gap: 15px;
	overflow: hidden;
}

.container {
	border: 1px solid #cccccc00;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
	border-radius: 30px;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	min-height: 160px;
}

/* titles */
h1 {
	font-family: 'Playfair Display';
	font-weight: 800;
}
h2 {
	font-family: 'Inter', sans-serif;
	font-weight: 700;
	font-size: 18px;
}

/* Tiles */
#ovya {
	display: flex;
	justify-content: center;
	align-items: center;
}
#title-text {
	font-size: 90px;
}
#ios,
#android {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
#ios-link {
	background-color: black;
	color: white;
	text-decoration: none;
	padding: 6px 10px;
	border-radius: 10px;
	margin-top: 20px;
	font-family: 'Inter', sans-serif;
	font-weight: 500;
	border: 1px solid black;
}
#ios-link:hover {
	background-color: #fff;
	border: 1px solid black;
	color: black;
}
#social-icons {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	flex-grow: 1;
	padding: 20px 0;
}
#get-started {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}
#get-started li {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
}
.ul-bullet {
	font-size: 12px;
	min-width: 20px;
	min-height: 20px;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.2);
	margin-right: 6px;
}
#get-started li p {
	text-align: left;
	line-height: 14px;
	font-size: 15px;
}

/* inputs */
.contact-div {
	font-size: 15px;
	position: relative;
	width: 100%;
	height: 36px;
}

.contact-input {
	width: 100%;
	height: 100%;
	font-size: 15px;
	line-height: 15px;
	padding: 16px 0 2px 5px;
	outline: none;
	background-color: white;
	border-bottom: 2px solid #0104002c;
	transition: all ease-in-out 0.4s;
	text-align: left;
}
.contact-input:focus {
	outline: none;
	border-color: #010400;
}

.contact-input:focus + .contact-label,
.contact-input:valid + .contact-label {
	font-size: 10px;
	padding-top: 0px;
	opacity: 0.5;
}

.contact-label {
	position: absolute;
	left: 3px;
	width: 100%;
	height: 100%;
	padding-top: 8px;
	transition: all ease-in-out 0.2s;
	opacity: 0.8;
	text-align: left;
	color: #676767;
}
.contact-label:hover {
	cursor: text;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000000s ease-in-out 0s;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
	html,
	body {
		background-color: #000;
	}

	.container {
		box-shadow: 0px 0px 8px rgba(255, 255, 255, 0.3);
	}
	#ios-link {
		background-color: white;
		color: black;
		border: 1px solid black;
	}
	#ios-link:hover {
		background-color: black;
		border: 1px solid white;
		color: white;
	}
	.ul-bullet {
		background-color: rgba(255, 255, 255, 0.2);
	}
	.contact-input {
		background-color: #000;
		color: #fff;
		border-bottom: 2px solid rgba(255, 255, 255, 0.2);
	}
	.contact-input:focus {
		border-color: #fff;
	}
	.contact-label {
		color: #fff;
	}
}
